import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import moment from "moment";

import { Filter } from "./";
import { ContactsOutlined } from "@mui/icons-material";
const BalanceTable = React.memo(({ ContentData, paginationchange, loader }) => {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const [state, setState] = useState({ data: [] });

  const [date, setDate] = useState({
    selectedValues: [],
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    //label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
  });
  const columns = [
    {
      name: t('Common.amount_type').toUpperCase(),
      selector:row=> row.amount_type,
      width : "30%"
    },
    {
      name: t('Common.amount').toUpperCase(),
      selector:row=> row.amount,
      sortable: true,
      cell: (row) => <span className={`${row.classnameamount} ${style.badge}`}>{Currency.currentCurr} {(row.amount*Currency.value).toFixed(Currency.precision)}</span>
    },
    {
      name: t('Common.type').toUpperCase(),
      selector:row=> row.type,
      sortable: true,
      cell: (row) => <span className={`${row.classnameamount} ${style.badge}`}>{row.type}</span>
    },
    {
        name: t('Common.transactionDate').toUpperCase(),
        selector:row=> row.transaction_date,
        sortable: true
    },
  ];

  const { data } = state;
  useEffect(() => {
    // total_amount: resData.total_amount,
    // category: t("ewallet."+resData.category, {
    //   username:"",
    // }) ,
    // transaction_date: resData.transaction_date,
    // tax: resData.tax,
    // serviceChange: resData.service_charge,
    // amount_payable: resData.amount_payable,
 
    
    let NewData = ContentData.data.map((resData) => {
      if (resData.type === "credit") {
        return {
          classnameamount: style.credit,
          amount: resData.amount,
          amount_type:t("ewallet."+resData.amount_type,{username:""}),
          type:resData.type,
          transaction_date:resData.date
        };
      } else if (resData.type === "debit") {
        return {
          classnameamount: style.debit,
          amount: resData.amount,
          // amount_type:resData.amount_type,
          amount_type: t("ewallet."+resData.amount_type,{username:""}),
          type:resData.type,
          transaction_date:resData.date
        };
      }
      return null;
    });
    setState((prev) => ({
      ...prev,
      data: NewData,
    }));
  }, [ContentData]);

  // onchange page
  const handlePageChange = (inPage) => {
    paginationchange(
      inPage,
      ContentData.perPage,
      "",
      "",
      ContentData.direction,
      "",
      date.selectedValues
    );
  };

  // handlePerRowsChange
  const handlePerRowsChange = (perPage, inPage) => {
   paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      date?.start,
      date?.end,

      "",
      "",
      date.selectedValues
    );
  };


  const handleOnSort = ({ selector }, sortDirection) => {
    // paginationchange(
    //   ContentData.inPage,
    //   ContentData.perPage,
    //   date?.start,
    //   date?.end,

    //   sortDirection,
    //   selector,
    //   date.selectedValues
    // );
  };

  const filterType = [
    { key: t("Common.debit"), value: "user_debit" },
    { key: t("Common.credit"), value: "user_credit" },
  ];

  //reset handler
  const resetData = (start, end, keys = "") => {
    loader.EarningsTable.reset = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      moment().startOf("month").format("YYYY-MM-DD"),
      "",
      "",
      ""
    );

    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: [],
    }));
  };

  //finter handler
  const filterHandler = (start, end, keys = "") => {
    loader.EarningsTable.search = true;
    console.log("wro")
    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      start,
      end,
      "",
      "",
      keys[0] ? keys[0].value : ""
    );
    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: keys[0] ? keys[0].value : "",
    }));
  };
  return (
    <div>
      <Filter
        filterHandler={filterHandler}
        type={filterType}
        resetHandler={resetData}
        isSingle={true}
        loader={loader}
      />
      <DataTable
        columns={columns}
        data={data}
        highlightOnHover
        progressPending={ContentData.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        //onSort={handleOnSort}
      />
    </div>
  );
});

export default BalanceTable;

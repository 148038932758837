import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import PvTable from "../../components/report/PvTable";
import OrderService from "../../../service/orderHistory/orderHistory";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory } from "react-router";
import moment from "moment";

function SponserReport({ t }) {
  const service = new OrderService();
  const history = useHistory();

  const [state, setState] = useState({
    loading: false,
    report: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
      startDate: moment(),
      endDate: moment(),
    },
  });

  useEffect(() => {
    loadData(state.report.inPage, state.report.perPage, state.report.startDate, state.report.endDate);
  }, [state.report.inPage, state.report.perPage, state.report.startDate, state.report.endDate]);

  const loadData = useCallback((inPage, perPage, startDate, endDate) => {
    const start = (inPage - 1) * perPage;
    service
      .getSponsorReport(start, perPage, startDate, endDate)
      .then((res) => {
        if (res.status) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            report: {
              ...prevState.report,
              totalRows: res.data.total_row,
              data: res.data,
              pending: false,
            },
          }));
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else if (res.error.code === 1057) {
            history.push({
              pathname: "/dashboard",
              state: {
                error: true,
                message: "permissionDenied",
              },
            });
          }
        }
      });
  }, [service, history]);

  const paginationchange = useCallback((inPage, perPage, startDate, endDate) => {
    setState((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        inPage,
        perPage,
        startDate,
        endDate,
      },
    }));
    loadData(inPage, perPage, startDate, endDate);
  }, [loadData]);

  const handleSearch = useCallback((start, end) => {
    setState((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        startDate: start,
        endDate: end,
      },
    }));
    loadData(state.report.inPage, state.report.perPage, start, end);
  }, [loadData, state.report.inPage, state.report.perPage]);

  return (
    <React.Suspense fallback={<Spinner />}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.sponsorReport")}
        </title>
      </Helmet>
      <PageTitle title="sponsorReport" buttonOn={false} />
      <PvTable
        {...state}
        service={service}
        paginationchange={paginationchange}
        handleSearch={handleSearch}
      />
    </React.Suspense>
  );
}

export default withTranslation()(SponserReport);

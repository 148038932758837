import React, { useState, useCallback } from "react";
import style from "./history.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

const PvTable = React.memo((props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);

  const columns = [
    {
      name: t("Common.LeftTeam"),
      selector: (row) => row["userInPV.username"],
    },
    {
      name: t("Common.RightTeam"),
      cell: (row) => (
        <span className={`${style.primaryBadge} ${style.badge}`}>
          {row?.group_pv}
        </span>
      ),
    },
    {
      name: t("Common.date"),
      selector: (row) => moment(row.createdAt).format("DD/MMM/YYYY"),
    },
  ];

  const [state, setState] = useState({
    selectedValues: [],
    start: moment().startOf("month"),
    end: moment(),
    label: `${moment()
      .startOf("month")
      .format("MMMM D, YYYY")} - ${moment().format("MMMM D, YYYY")}`,
  });

  const { start, end, label, selectedValues } = state;

  const options = {
    Today: [moment().toDate(), moment().toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week").toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "This Year": [
      moment().startOf("year").toDate(),
      moment().endOf("year").toDate(),
    ],
  };

  const handleCallback = (start, end) => {
    setState({
      start,
      end,
      label: `${start.format("MMMM D, YYYY")} - ${end.format("MMMM D, YYYY")}`,
      selectedValues: [],
    });
  };

  const resetValue = () => {
    setState({
      selectedValues: [],
      start: moment().startOf("month"),
      end: moment(),
      label: `${moment()
        .startOf("month")
        .format("MMMM D, YYYY")} - ${moment().format("MMMM D, YYYY")}`,
    });
    props.handleSearch(
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
      []
    );
  };

  const submitHandler = () => {
    props.handleSearch(
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      selectedValues
    );
  };
  console.log(props?.report, "propsprops");
  return (
    <div
      className={`${
        currentTheme === "theme-dark"
          ? style.dark_mode_container
          : style.container
      }`}
    >
      <div style={{ padding: "10px", background: "white" }}>
        <div className={style.filterSection}>
          <div>
            <DateRangePicker
              initialSettings={{
                startDate: start.toDate(),
                endDate: end.toDate(),
                ranges: options,
              }}
              onCallback={handleCallback}
            >
              <div
                className={`${
                  currentTheme === "theme-dark"
                    ? style.dark_mode_DateRangePicker
                    : style.DateRangePicker
                }`}
              >
                <i className="fa fa-calendar"></i>&nbsp;
                <span>{label}</span> <i className="fa fa-caret-down"></i>
              </div>
            </DateRangePicker>
          </div>
          <div className={style.filterButton}>
            <Button
              variant="info"
              className={style.padding5}
              onClick={submitHandler}
            >
              {t("Button.search")}
            </Button>
            <Button className={style.padding5} onClick={resetValue}>
              {t("Button.reset")}
            </Button>
          </div>
        </div>
      </div>
      <div style={{ padding: "10px", background: "white", marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#0000001f",
          }}
        >
          <h4>{t("Common.LeftTeam")} </h4> <h4>{t("Common.RightTeam")}</h4>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h4>{props?.report?.data?.totalGroupPvL ?? 0} </h4>{" "}
          <h4>{props?.report?.data?.totalGroupPvR ?? 0}</h4>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h4>{t("Common.total")} </h4>{" "}
          <h4>{Number(props?.report?.data?.totalGroupPvR) + Number(props?.report?.data?.totalGroupPvL) ?? 0}</h4>
        </div>
      </div>
    </div>
  );
});

export default PvTable;

import style from "./Common.module.scss";
import { ListGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import proPic from "../../../assets/images/nophoto/no_photo.jpg";
const ToolTips = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  if(props.down_count){
    props.config["down"]=props.down_count
  }
  console.log("config",props)
  const TooltipConfig = (item, index) => {
    if (props.config[item]) {
      if (item === "join_date") {
        return (
          <ListGroup.Item
            key={index}
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
          >
            <div>{t(`Common.${item}`)}</div>
            <div className={style.text_right}>
            <span>:</span> {moment(props.join_date).format("MMM Do YY")}
            </div>
          </ListGroup.Item>
        );
      } else if (item === "group_pv") {
        return (
          <ListGroup.Item
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
            key={index}
          >
            <div>{t(`Common.groupPV`)}</div>
            <div  className={style.text_right}>
              <span>:</span> {props.group_pv ? props.group_pv : 0 }
            </div>
          </ListGroup.Item>
        );
      } else if (item === "left") {
        return (
          <ListGroup.Item
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
            key={index}
          >
            <div>{t(`Common.left`)}</div>
            <div  className={style.text_right}>
            <span>:</span> {props.lefts ? props.lefts :0}
            </div>
          </ListGroup.Item>
        );
      } else if (item === "right") {
        return (
          <ListGroup.Item
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
            key={index}
          >
            <div>{t(`Common.right`)}</div>
            <div  className={style.text_right}>
            <span>:</span> {props.rights ? props.rights :0}
            </div>
          </ListGroup.Item>
        );

      } 
      else if (item === "down") {
        return (
          <ListGroup.Item
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
            key={index}
          >
            <div>{t(`Common.Downline`)}</div>
            <div  className={style.text_right}>
            <span>:</span> {props.down_count ? props.down_count :0}
            </div>
          </ListGroup.Item>
        );
      
      }
      else if (
        item !== "rank_status" &&
        item !== "first_name" &&
        props[item] !== null && props[item] !== undefined
      ) {
        return (
          <ListGroup.Item
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tooltipListItem}`
                : `${style.tooltipListItem}`
            }`}
            key={index}
          >
            <div>{t(`Common.${item}`)}</div>
            <div  className={style.text_right}><span>:</span> {props[item]}</div>
          </ListGroup.Item>
        );
      }
    }
  };
  return (
    <>
      <div className={style.tooltipHeader}>
        {props.image ? (
          <img src={props.image} alt="" />
        ) : (
          <img src={proPic} alt="" />
        )}

        <p>{props.user_name}</p>
        {props.config && props.full_name && <p>{`${props.full_name}`}</p>}
      </div>
      <div className={style.content}>
        <ListGroup
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_tooltipList}`
              : `${style.tooltipList}`
          }`}
        >
          {Object.keys(props.config).map((item, index) => {
            
            if (props.config[item]) {
              console.log("--------",item)
              return TooltipConfig(item, index);
            }
            return false;
          })}

          {/* {props.down_count&&(<>
            <div>{t(`Common.${props.down_count}`)}</div>
            <div  className={style.text_right}><span>:</span> {props.down_count}</div>
            </>
          )} */}
          
          {props.config.rank_status && (
            <div>
              <Button
                style={{
                  background: props.rank_color ?? "#7266ba",
                  borderColor: "transparent",
                }}
              >
                {props.rank_name ?? "NA"}
              </Button>
            </div>
          )}
        </ListGroup>
      </div>
    </>
  );
};

export default ToolTips;

import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "react-data-table-component-extensions/dist/index.css";
import { CurrencyDecimalFormat } from "../../helper";
import style from "./invoice.module.scss";

function ReportTable(props) {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const columns = [
    {
      name: t("Common.invoice"),
      selector: (row) => row.invoice_no,
      //   width : "30%"
    },

    {
      name: t("Common.totalAmount"),
      selector: (row) => row.amount,
      cell: (row) => (
        <span className={`${style.primaryBadge} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {CurrencyDecimalFormat(
            row.amount * Currency.value,
            Currency.precision
          )}
        </span>
      ),
    },

    {
      name: t("Common.paymentMethod"),
      selector: (row) => row.payment_method,
      cell: (row) => <span>{t(`Common.${row.payment_method}`)}</span>,
    },
    {
      name: t("Common.purchaseDate"),
      selector: (row) => row.purchase_date,
    },
  ];
  const handlePerRowsChange = (perPage, inPage) => {
    props.paginationchange(inPage, perPage);
  };
  const handlePageChange = (inPage) => {
    props.paginationchange(inPage, props.perPage);
  };
  return (
    <div>
      <DataTable
        columns={columns}
        data={props.data}
        highlightOnHover
        progressPending={props.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={props.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        // onSort={handleOnSort}
      />
    </div>
  );
}

export default ReportTable;

import React from 'react';
import style from './invoice.module.scss';
import {AlertBs,AddonNotification} from '../common';
import { Button } from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PrintContent from './PrintContent';
import {Alert} from 'react-bootstrap';
function InvoiceContent(props) {
    const {t} = useTranslation();
    const printReport = () =>{
        var myPrintContent = document.getElementById('print_area');
        var myPrintWindow = window.open("", "Print Report", 'left=300,top=100,width=700,height=500');
        myPrintWindow.document.write(myPrintContent.innerHTML);
        myPrintWindow.document.close();
        myPrintWindow.focus();
        myPrintWindow.print();
        myPrintWindow.close();
        return false;
    }
    return (
        <div className={style.invoiceContent}>


<Alert variant='success' show={props.alert.show}  dismissible>
 <span dangerouslySetInnerHTML={{__html : t(props.alert.message,{ number:props.invoice_no})}} style={{color:'green'}}></span>
                        </Alert>


          
            <div className={`${style.printButton}`}>
                <Button className={`${style.btnPrimary} ${style.btnAddon}`} onClick={printReport}>
                    <i className="fa fa-print"></i>
                    {t('Button.clickHereToPrint')}
                </Button>
            </div>
            <PrintContent
                {...props}
            />
        </div>
    )
}

export default InvoiceContent

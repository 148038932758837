import React, { useState } from "react";
import style from "./history.module.scss";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import DataTable from "react-data-table-component";

import Invoice from "./Invoice";

const HistoryContent = (props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const currency = useSelector((state) => state.curr);

  const [state, setState] = useState({
    modalShow: false,
    modalData: {},
  });
  console.log(props, "===============props");
  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      modalShow: false,
      modalData: {},
    }));
  };

  const columns = [
    {
      name: t("Common.orderId"),
      selector: (row) => row.order_id,
      width : "10%"
    },

    {
      name: t("Common.memberName"),
      selector: (row) => row.customerInfo,
    },

    {
      name: t("Common.totalAmount"),
      cell: (row) => (
        <span className={`${style.primaryBadge} ${style.badge}`}>
          {currency.currentCurr}{" "}
          {(parseInt(row.total) * currency.value).toFixed(currency.precision)}
        </span>
      ),
    },

    {
      name: t("payout.paymentMethod"),
      selector: (row) => row.payment_method,
    },
    {
      name: t("Common.orderDate"),
      selector: (row) => moment(row.date_added).format("DD/MMM/YYYY"),
    },
    {
      name: t("Common.action"),
      cell: (row) => (
        <span
          className={style.actionIcon}
          onClick={() => handleShow(row)}
          style={{ color: "#7266ba", fontSize: "18px" }}
        >
          <i className="fa fa-eye"></i>
        </span>
      ),
      width : "10%"
    },
  ];

  const handleShow = (data) => {
    setState((prev) => ({
      ...prev,
      modalShow: true,
      modalData: data,
    }));
  };

  const handlePerRowsChange = (perPage, inPage) => {
    props.paginationchange(inPage, perPage);
  };
  const handlePageChange = (inPage) => {
    props.paginationchange(inPage, props.report.perPage);
  };

  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_container}`
          : `${style.container}`
      }`}
    >
      <DataTable
        columns={columns}
        data={props.report.data}
        highlightOnHover
        progressPending={props.report.pending}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        responsive
        paginationTotalRows={props.report.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        // onSort={handleOnSort}
      />

      {state.modalData && (
        <Invoice
          show={state.modalShow}
          handleClose={handleClose}
          data={state.modalData}
          currency={currency}
        />
      )}
    </div>
  );
};

export default HistoryContent;

{
  /* <Table responsive bordered>
        <thead>
          <tr>
            <th>{t("Common.orderId")}</th>
            <th>{t("Common.memberName")}</th>
            <th>{t("Common.totalAmount")}</th>
            <th>{t("payout.paymentMethod")}</th>
            <th>{t("Common.orderDate")}</th>
            <th>{t("Common.action")}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.length === 0 ? (
            <td colSpan="13" align="center">
              No Order
            </td>
          ) : (
            props.data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.order_id}</td>
                  <td>{item.customer_name} {"("}{item.customer_username}{")"}</td>
                  <td>
                    {currency.currentCurr}{" "}
                    {(parseInt(item.total) * currency.value).toFixed(
                      currency.precision
                    )}
                  </td>
                  <td>{item.payment_method}</td>
                  <td>{moment(item.date_added).format("DD/MMM/YYYY")}</td>
                  <td className="text-center">
                    <span
                      className={style.actionIcon}
                      onClick={() => handleShow(item)}
                    >
                      <i className="fa fa-eye"></i>
                    </span>
                  </td>
                </tr>
              );
            })
          )}
          <tr></tr>
        </tbody>
        {/* <div>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button> */
}

{
  /* Display page numbers */
}
{
  /* {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */
}
// </Table>


//http://68.178.203.106:5000/api/web/

import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME = companyName.companyName ?? 'Company Name';
const BASE_URL = "https://admin.legacylifechoice.com/node_api/web/";
const IMG_URL = "https://admin.legacylifechoice.com/node_api/uploads/images/";
const LEAD_URL = "https://admin.legacylifechoice.com/node_api/uploads/images/";
const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='903's
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const BASE = "https://admin.legacylifechoice.com";



// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://192.168.9.47:5055/node_api/web/";
// const IMG_URL = "http://192.168.9.47:5055/node_api/uploads/images/";
// const LEAD_URL = "http://192.168.9.47:5055/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.9.47:5055";





// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://68.178.203.106:5000/node_api/web/";
// const IMG_URL = "http://68.178.203.106:5000/node_api/uploads/images/";
// const LEAD_URL = "http://68.178.203.106:5000/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://68.178.203.106:5000";

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://162.19.146.135:1168/node_api/web/";
// const IMG_URL = "http://162.19.146.135:1168/node_api/uploads/images/";
// const LEAD_URL = "http://162.19.146.135:1168/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://162.19.146.135:1168";

// Akhila - local

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://192.168.23.47:5005/node_api/web/";
// const IMG_URL = "http://192.168.23.47:5005/node_api/uploads/images/";
// const LEAD_URL = "http://192.168.23.47:5005/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.23.47:5005";



// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "https://admin.emmanual.iossmlm.com/node_api/web/";
// const IMG_URL = "https://admin.emmanual.iossmlm.com/node_api/uploads/images/";
// const LEAD_URL = "https://admin.emmanual.iossmlm.com/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "https://admin.emmanual.iossmlm.com";

// AA panel 


// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "https://adminemmanual.iossmlm.com/node_api/web/";
// const IMG_URL = "https://adminemmanual.iossmlm.com/node_api/uploads/images/";
// const LEAD_URL = "https://adminemmanual.iossmlm.com/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "https://adminemmanual.iossmlm.com";

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY,
    LEAD_URL
}

// // //local

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME = companyName.companyName ?? 'Company Name';
// const BASE_URL = "http://192.168.23.44:5000/node_api/web/";
// const IMG_URL = "http://192.168.23.44:5000/node_api/uploads/images/";
// const LEAD_URL = "http://192.168.23.44:5000/node_api/uploads/images/";
// const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903's
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.23.44:5000/";
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY,
//     LEAD_URL
// }


import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import HistoryContent from "../../components/orderHistory/historyContent";
import OrderService from "../../../service/orderHistory/orderHistory";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory, withRouter } from "react-router";

function OrderHistory({ t }) {
  let service = new OrderService();
  let history = useHistory();

  const [state, setState] = useState({
    loading: true,
    report: {
      totalRows: 0,
      data: [],
      pending: true,
      perPage: 10,
      inPage: 1,
    },
  });

  useEffect(() => {
    loadData(state.report.inPage, state.report.perPage);
  }, []);

  //load the data
  const loadData = (
    inPage = state.report.inPage,
    perPage = state.report.perPage
  ) => {
    let start = (inPage - 1) * perPage;
    service.getOrderHistory(start, perPage).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          report: {
            ...prevState.report,
            totalRows: res.data.total_row,
            data: res.data.order_details,
            pending: false,
          },
        }));
      } else {
        if (res.error.code === 1002) {
          history.push("/logout");
        } else if (res.error.code === 1057) {
          history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };
  const paginationchange = (inPage, perPage = state.report.perPage) => {
    setState((prevState) => ({
      ...prevState,
      report: {
        ...prevState.report,
        inPage: inPage,
        perPage: perPage,
      },
    }));
    loadData(inPage, perPage);
  };

  // const [state, setState] = useState({
  //     loading: true,
  //     data: []
  // })

  // useEffect(() => {
  //     service.getOrderHistory().then(res=>{
  //         if(res.status){
  //             setState({
  //                 loading  :false,
  //                 data  : res.data.order_details
  //             })
  //         }else{
  //             if(res.error.code === 1002){
  //                 history.push('/logout');
  //             }else if(res.error.code === 1057 ){
  //                 history.push({
  //                     pathname: '/dashboard',
  //                     state: {
  //                         error: true,
  //                         message : 'permissionDenied'
  //                     }
  //                 });
  //             }
  //         }
  //     })

  //  },[]);

  if (state.loading) {
    return <Spinner />;
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.orderHistory")}
        </title>
      </Helmet>
      <PageTitle title="orderHistory" buttonOn={false} />
      <HistoryContent
        {...state}
        service={service}
        paginationchange={paginationchange}
      />
    </React.Suspense>
  );
}
export default withTranslation()(withRouter(OrderHistory));
